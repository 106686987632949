<template>
  <div class="data-box">
    <div class="data-box-head">
      感谢您对我们活动的支持与配合，请您为展示二维码的<br/>
      人员提供相应的服务，并确认最终消费的数量与金额。
    </div>
    <div class="data-box-body">
      <div class="body-div">
        <div class="body-div-item" v-for="(item,index) in clearKeyValueList" :key="index">
          <span class="span-key span-line">{{ item.key }}</span>
          <span class="span-value" :style="{width: item.phone ? '60%' : '',}">{{ item.value ? item.value : "无" }}</span>
          <a :href="'tel:' + item.phone" class="iconfont icon-dianhua1" v-show="item.phone"></a>
        </div>
      </div>
      <div class="body-div">
        <div class="body-div-title align-center">
          请在下方登记实际消费结果
        </div>
        <div class="body-div-item">
          <div class="span-key">消费内容</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.roomEnergyFood"
                placeholder="请输入消费内容"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">消费金额</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.num1"
                placeholder="请输入消费金额"
                type="number"
                maxlength="10"
                @input="inputHandler"
            >
              <template #button>
                <span>元</span>
              </template>
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">消费说明</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.note"
                placeholder="请输入消费说明"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">验证码</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.code"
                placeholder="请输入领取人手机号码后4位"
                maxlength="4"
                type="digit"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">经办人</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePerson"
                placeholder="请输入经办人"
            >
            </van-field>
          </div>
        </div>
        <div class="body-div-item">
          <div class="span-key">联系电话</div>
          <div class="span-input">
            <van-field
                v-model="sendInfo.handlePhone"
                placeholder="请输入联系电话"
                maxlength="11"
                type="digit"
            >
            </van-field>
          </div>
        </div>
      </div>
    </div>
    <div class="data-box-foot">
      <van-button round block type="info" @click="onSubmit">提交</van-button>
    </div>
    <van-overlay :show="$store.state.showOverlay" :z-index="1010">
      <van-loading class="loading-box" type="spinner" color="#1989fa"/>
    </van-overlay>
  </div>
</template>

<script>
import {
  energyWashRoomFoodRegister
} from "@/api/index";

export default {
  name: "parClear_cost",
  data() {
    return {
      clearKeyValueList: [
        { key: "人员姓名", value: "" },
        { key: "车辆牌号", value: "" },
        { key: "车辆名称", value: "" },
        { key: "消费备注", value: "" },
        { key: "单次限领", value: "" },
        { key: "消费周期", value: "" },
        { key: "服务负责人", value: "", phone: "" },
        { key: "使用单位", value: "" }
      ],
      sendInfo: {
        "actId": 0,
        "activityId": 0,
        "handlePerson": "",
        "handlePhone": "",
        "phone": "",
        "note": "",
        "num1": null,
        "roomEnergyFood": "",
        "code": null,
        "type": 3
      }
    }
  },
  mounted() {
    let that = this;
    that.initData();
  },
  methods:{
    initData(){
      let that = this;
      let id = that.$route.query.id;
      that.$tool.getActObjInfo(id, (data) => {
        that.sendInfo.actId = data.activityId;
        that.sendInfo.activityId = data.id;
        that.sendInfo.compangyId = data.relateId;
        that.sendInfo.phone = data.chargePhone;
        data.activitySets.forEach(item => {
          if(item.relateType === 12){
            that.clearKeyValueList[0].value = item.chargeName;
          }
          if(item.relateType === 13){
            that.clearKeyValueList[1].value = item.noteNameDateStop;
            that.clearKeyValueList[2].value = item.checkTypeNumArticle;
          }
          if(item.relateType === 6){
            that.clearKeyValueList[3].value = item.noteNameDateStop;
            that.clearKeyValueList[4].value = item.checkTypeNumArticle;
          }
          if(item.relateType === 17){
            that.clearKeyValueList[6].value = item.chargeName;
            that.clearKeyValueList[6].phone = item.chargePhone;
          }
        })
        that.clearKeyValueList[5].value = data.actStartDate + " ~ " + data.actEndDate;
        that.clearKeyValueList[7].value = data.postName;
      });
    },
    //消费金额输入验证
    inputHandler(){
      let that = this;
      let reg = /^(([0-9]+)|([0-9]+\.)|([0-9]+\.[0-9]{1,2}))$/;
      that.sendInfo.num1 = reg.test(that.sendInfo.num1) ? that.sendInfo.num1 : "";
    },
    onSubmit(){
      let that = this;
      if(that.sendInfo.roomEnergyFood === ""){
        that.$toast('请输入消费内容！');
        return;
      }
      if(that.sendInfo.num1 === ""){
        that.$toast('请输入消费金额！');
        return;
      }
      if(that.sendInfo.code === ""){
        that.$toast('请输入手机后四位验证码！');
        return;
      }
      let str = that.sendInfo.phone.substring(that.sendInfo.phone.length-4);
      if(that.sendInfo.code != str){
        that.$toast('验证码有误！');
        return;
      }
      if(that.sendInfo.handlePerson === ""){
        that.$toast('请输入经办人姓名！');
        return;
      }
      if(that.sendInfo.handlePhone === ""){
        that.$toast('请输入经办人联系电话！');
        return;
      }
      energyWashRoomFoodRegister(that.sendInfo).then(res => {
        that.$tool.resValidation(res,() => {
          that.$router.push({
            path: `/par_success`,
            query: {
              type: 5
            }
          })
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/css/common.scss";
</style>